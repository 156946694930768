import React, { useEffect, useState } from "react";
import {
  FormLabel,
  InputAdornment,
  TextField,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import Style from "./styles/Filtered.module.css";
import CustomerList from "../dashboard/CustomerList";
import { Search } from "@mui/icons-material";
import OptionPill from "../../components/option-pill/OptionPill";
import { useAuth } from "../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  ENDPOINT,
  TOAST_CONFIG,
  USER_TYPE,
  genderData,
} from "../../config/constants";
import { POST, POST_MULTIPART } from "../../services/axiosRequestHandler";
import {
  convertDateFormat,
  formatDatetime,
  formatISODateString,
  generateBatches,
} from "../../utils";
import {
  resetMainFilter,
  setActiveExportTab,
  setEmailPayload,
  setPreviousPageSize,
  setPreviousScreen,
  setSelectedContacts,
  setSelectedIds,
  setSelectedReservations,
} from "../../redux/slices/masterDataSlice";
import { useLocation, useNavigate } from "react-router-dom";
import Wide from "../../assets/images/wide.svg";
import Narrow from "../../assets/images/narrow.svg";
import NoDataFound from "../../assets/images/no-data.svg";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';

const pageSizes = ["0-500", "501-1000"];
const spendingData = [
  {
    label: "₹ 2K",
    amount: "2K",
  },
  {
    label: "₹ 4K",
    amount: "4K",
  },
  {
    label: "₹ 6K",
    amount: "6K",
  },
  {
    label: "₹ 10K+",
    amount: "10K+",
  },
];
function Filtered({ exports, reservationExport, reservation, checkin }) {
  const isMobile = useMediaQuery("(max-width:650px)");
  const { logout, role } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    previousPageSize,
    mainFilter,
    selectedContacts,
    selectedIds,
    selectedReservationIds,
    age,
    categories,
    restaurantsList
  } = useSelector((state) => state.masterData);
  const dispatch = useDispatch();
  const [batchSizes, setBatchSizes] = useState([]);
  const [pageSize, setPageSize] = useState(
    previousPageSize ? previousPageSize : "0-499"
  );
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [filteredClients, setFilteredClients] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [filterType, setFilterType] = useState(0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);
  const [totalPaxCount, setTotalPaxCount] = useState(0);
  const [totaTableCount, setTotalTableCount] = useState(0);

  const [firstLoad, setFirstLoad] = useState(true);

  const [open, setOpen] = useState(false);
  const [opentable, setOpenTable] = useState(false);

  const handleTouchStart = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000); // Tooltip will disappear after 2 seconds (adjust as needed)
  };
  const handleTouchStartTable = () => {
    setOpenTable(true);
    setTimeout(() => {
      setOpenTable(false);
    }, 2000); // Tooltip will disappear after 2 seconds (adjust as needed)
  };
  // useEffect(() => {
  //   getFilteredClientList();
  // }, [pageSize, mainFilter, filterType]);
  //  openMainFilter,
  // openReservationFilter,
  // openCheckInFilter,
  useEffect(() => {
    setPageSize(previousPageSize ? previousPageSize : "0-499");
  }, [mainFilter, filterType]);

  useEffect(() => {
    !searchKey && getFilteredClientList();
  }, [pageSize, reservationExport, exports]);

  useEffect(() => {
    return () => {
      setSearchData([]);
      setSearchKey("");
      // reservationExport && dispatch(setActiveExportTab("database"));
    };
  }, [exports, reservationExport]);

  useEffect(() => {
    let searchData;
    if (!firstLoad) {
      setSearchLoading(true);
      searchData = setTimeout(() => {
        searchKey ? handleSearch() : getFilteredClientList();
      }, 1000);
    }
    return () => {
      clearTimeout(searchData);
      setSearchData([]);
    };
  }, [searchKey, pageSize, mainFilter, filterType, reservationExport, exports]);

  const handlePagination = (pageSize) => {
    setPageSize(pageSize);
    dispatch(setPreviousPageSize(""));
    dispatch(setSelectedContacts([]));
    dispatch(setSelectedIds([]));
    setSearchKey("");
  };
  const getFilteredClientList = async () => {
    try {
      firstLoad ? setLoading(true) : setSearchLoading(true);
      const payload = new FormData();
      payload.append("batch", pageSize);
      // payload.append("gender", mainFilter.gender === "Male" ? 1 : 0);
      payload.append("isDnd", mainFilter.dnd ? 1 : 0);
      payload.append("isCancelled", mainFilter.isCancelled ? 1 : 0);
      if (!reservation) {
        payload.append("rating", mainFilter.rating);
      }
      if (reservation || reservationExport) {
        payload.append(
          "fromDate",
          mainFilter.fromDate
            ? formatISODateString(new Date(mainFilter.fromDate).toISOString())
            : reservationExport
              ? formatISODateString(new Date().toISOString())
              : ""
        );
        payload.append(
          "toDate",
          mainFilter.toDate
            ? formatISODateString(new Date(mainFilter.toDate).toISOString())
            : reservationExport
              ? formatISODateString(new Date().toISOString())
              : ""
        );
      } else {
        if (mainFilter.startTime) {
          payload.append(
            "fromDate",
            mainFilter.fromDate
              ? `${formatISODateString(
                new Date(mainFilter.fromDate).toISOString()
              )} ${new Date(mainFilter.startTime).toLocaleTimeString()}`
              : ""
          );
        } else
          payload.append(
            "fromDate",
            mainFilter.fromDate
              ? formatISODateString(new Date(mainFilter.fromDate).toISOString())
              : ""
          );

        if (mainFilter.endTime) {
          payload.append(
            "toDate",
            mainFilter.toDate
              ? `${formatISODateString(
                new Date(mainFilter.toDate).toISOString()
              )} ${new Date(mainFilter.endTime).toLocaleTimeString()}`
              : ""
          );
        } else
          payload.append(
            "toDate",
            mainFilter.toDate
              ? formatISODateString(new Date(mainFilter.toDate).toISOString())
              : ""
          );
      }
      mainFilter?.age.forEach((item) => payload.append("age[]", item));

      // mainFilter?.gender !== null &&
      //   payload.append("gender", mainFilter?.gender);

      mainFilter?.gender.forEach((item) => payload.append("gender[]", item));

      mainFilter?.category.forEach((item) =>
        payload.append(
          reservation || reservationExport ? "restId[]" : "category[]",
          item
        )
      );
      payload.append("searchType", filterType);

      mainFilter?.spending.forEach((item) =>
        payload.append("spending[]", item ? `${item}K` : "")
      );
      // payload.append(
      //   "spending",
      //   mainFilter?.spending ? `${mainFilter.spending}K` : ""
      // );

      if (reservation || reservationExport) {
        if (
          mainFilter?.fromTime?.hh &&
          mainFilter?.fromTime?.mm &&
          mainFilter?.fromTime?.meridiem
        )
          payload.append(
            "fromBookingTime",
            `${mainFilter.fromTime.hh}:${mainFilter.fromTime.mm} ${mainFilter.fromTime.meridiem}`
          );
        else payload.append("fromBookingTime", "");

        if (
          mainFilter?.toTime?.hh &&
          mainFilter?.toTime?.mm &&
          mainFilter?.toTime?.meridiem
        ) {
          payload.append(
            "toBookingTime",
            `${mainFilter.toTime.hh}:${mainFilter.toTime.mm} ${mainFilter.toTime.meridiem}`
          );
        } else payload.append("toBookingTime", "");
      }

      const response = await POST_MULTIPART(
        checkin
          ? ENDPOINT.GET_FILTERED_CHECKINOUT
          : reservation
            // ? ENDPOINT.GET_FILTERED_RESERVATION
            ?  ENDPOINT.GET_EXPORT_RESERVATION_FILTER_DATA
            : reservationExport
              ? ENDPOINT.GET_EXPORT_RESERVATION_FILTER_DATA
              : ENDPOINT.GET_FILTERED_CLIENTS,
        payload
      );
      if (response?.response?.data?.status === 200) {
        setFilteredClients(response?.response?.data);
        setTotalRecordsCount(response?.response?.data?.totalRecordsCount || 0);
        setTotalPaxCount(response?.response?.data?.totalPax || 0);
        setTotalTableCount(response?.response?.data?.totalTablePdr || 0);

        setBatchSizes(
          generateBatches(response?.response?.data?.totalRecordsCount || 0)
        );

        setSearchData(response?.response?.data?.data);
        dispatch(
          setEmailPayload({
            reservation,
            reservationExport,
            filterType,
            pageSize,
            filterCount: response?.response?.data?.totalRecordsCount,
            filterMode: checkin
              ? "checkinout"
              : reservation || reservationExport
                ? "reservation"
                : "database",
          })
        );
      } else if (
        response?.response?.data?.status === 401 &&
        response?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setLoading(false);
      setSearchLoading(false);
      setFirstLoad(false);
    }
  };

  const handleSelectAll = () => {
    if (reservationExport ) {
      const allIds = filteredClients?.data?.map((data) => data?.reservationId);
      dispatch(setSelectedReservations(allIds));
    } else {
      const allContacts = filteredClients?.data?.map((data) => data?.contactNo);
      const allIds = filteredClients?.data?.map((data) => data?.id);
      dispatch(setSelectedContacts(allContacts));
      dispatch(setSelectedIds(allIds));
    }
  };

  const deselectAll = () => {
    if (reservationExport) {
      dispatch(setSelectedReservations([]));
    } else {
      dispatch(setSelectedContacts([]));
      dispatch(setSelectedIds([]));
    }
  };

  const populateFilters = () => {
    let appliedFilters = [];
    age?.data?.data?.map((age) => {
      if (mainFilter?.age?.includes(age.id)) {
        appliedFilters.push(`${age.ageFrom}-${age?.ageTo}`);
      }
    });
    categories?.data?.data?.map((cat) => {
      if (mainFilter?.category?.includes(cat.id)) {
        appliedFilters.push(cat?.name);
      }
    });
    genderData.map((gender) => {
      if (mainFilter?.gender?.includes(gender.label)) {
        appliedFilters.push(gender?.label);
      }
    });

    if (mainFilter?.dnd) {
      appliedFilters.push("DND");
    }
    // if (mainFilter?.spending?.length) {
    //   mainFilter.spending.map((spending) => {
    //     appliedFilters.push(`₹ ${spending}K`);
    //   });
    // }
    if (mainFilter?.spending?.length) {
      if (mainFilter?.spending[0] === mainFilter?.spending[1]) {
        appliedFilters.push(`₹ ${mainFilter?.spending[0]}K`);
      } else
        appliedFilters.push(
          `₹ ${mainFilter?.spending[0]}K to ${mainFilter?.spending[1]}K`
        );
    }

    if (
      mainFilter?.fromTime?.hh &&
      mainFilter?.fromTime?.mm &&
      mainFilter?.fromTime?.meridiem
    ) {
      appliedFilters.push(
        `From Time: ${mainFilter.fromTime.hh}:${mainFilter.fromTime.mm} ${mainFilter.fromTime.meridiem}`
      );
    }
    if (
      mainFilter?.toTime?.hh &&
      mainFilter?.toTime?.mm &&
      mainFilter?.toTime?.meridiem
    ) {
      appliedFilters.push(
        `To Time: ${mainFilter.toTime.hh}:${mainFilter.toTime.mm} ${mainFilter.toTime.meridiem}`
      );
    }

    if (mainFilter?.fromDate) {
      appliedFilters.push(
        `From Date: ${convertDateFormat(
          new Date(mainFilter?.fromDate).toLocaleDateString("en-IN"),
          "dd-mm-yyyy"
        )}`
      );
    }
    if (mainFilter?.toDate) {
      appliedFilters.push(
        `To Date: ${convertDateFormat(
          new Date(mainFilter?.toDate).toLocaleDateString("en-IN"),
          "dd-mm-yyyy"
        )}`
      );
    }

    if (mainFilter?.rating) {
      appliedFilters.push(`${mainFilter?.rating} Star rating`);
    }

    return appliedFilters;
  };

  const handleSearchChange = (e) => {
    if (e.target.value.length > 0) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }
    setSearchKey(e.target.value);
  };

  const handleSearch = async () => {
    try {
      // const filteredArray = filteredClients?.data?.filter((item) => {
      //   if (
      //     item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
      //     item.lastName
      //       .toLowerCase()
      //       .includes(e.target.value.toLowerCase()) ||
      //     item.contactNo.includes(e.target.value)
      //   )
      //     return item;
      // });

      const payload = new FormData();
      payload.append("search", searchKey);
      const resp = await POST_MULTIPART(
       ( reservationExport || (reservation && location.pathname === "/filtered-reservations") )
          ? ENDPOINT.RESERVATION_EXPORT_SEARCH
          : ENDPOINT.FILTER_SEARCH,
        // ENDPOINT.RESERVATION_EXPORT_SEARCH,
        payload
      );
      if (resp?.response?.data?.status === 200) {
        deselectAll();
        setSearchData(resp?.response?.data?.data);
        setTotalPaxCount(resp?.response?.data?.totalPax || 0);
        setTotalTableCount(resp?.response?.data?.totalTablePdr || 0);
      }

      // setSearchData(filteredClients?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setSearchLoading(false);
    }
  };

  const handleNext = () => {
    navigate("/send-template");
    dispatch(setPreviousPageSize(pageSize));
    dispatch(setPreviousScreen(location?.pathname));
  };

  const handleExport = async (e) => {
    handleClose();
    setSubmitting(true);

    try {
      const payload = new FormData();
      if (reservationExport)
        selectedReservationIds.forEach((item) => payload.append("id[]", item));
      else selectedIds.forEach((item) => payload.append("id[]", item));
      const response = await POST_MULTIPART(
        reservationExport ? ENDPOINT.EXPORT_RESERVATIONS : ENDPOINT.EXPORT,
        payload
      );
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setSubmitting(false);
      dispatch(setSelectedContacts([]));
      dispatch(setSelectedIds([]));
      dispatch(setSelectedReservations([]));
    }
  };

  const clearAllFilters = () => {
    dispatch(resetMainFilter());
    dispatch(setSelectedContacts([]));
    dispatch(setSelectedIds([]));
  };

  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOpen = () => {
    setOpenConfirmationDialog(true);
  };

  const getSelectLabel = () => {
    if (reservationExport) {
      if (searchData?.length === selectedReservationIds?.length) {
        return "Deselect All";
      } else {
        return "Select All";
      }
    } else if (searchData?.length === selectedContacts?.length) {
      return "Deselect All";
    } else {
      return "Select All";
    }
  };
  const handleSelectClick = () => {
    if (reservationExport) {
      if (searchData?.length === selectedReservationIds?.length) {
        deselectAll();
      } else {
        handleSelectAll();
      }
    } else if (searchData?.length === selectedContacts?.length) {
      deselectAll();
    } else {
      handleSelectAll();
    }
  };
  if (loading && !searchData.length) {
    return (
      <div className="loaderWrapper">
        <CircularProgress color="primary" size={56} />
      </div>
    );
  }
  return (
    <>
      <div className={Style.container}>
        <div className={Style.labelWrapper}>
          <div className={Style.filterWrapper}>
            <p className={Style.label}>
              {reservationExport
                ? "Filtered reservations : "
                : "Filtered clients : "}
              {searchKey ? searchData?.length : totalRecordsCount}
            </p>
            {reservationExport && <div className={Style.totalIconLeft}>
              <p className={Style.totalIconsWrapper}>
                <Tooltip
                  title="Total Pax"
                  open={open}
                  onOpen={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  placement="top"
                  arrow
                >
                  <SupervisorAccountIcon fontSize="large" onTouchStart={handleTouchStart} />
                </Tooltip>
                <span className={Style.ml1}>
                  {totalPaxCount}
                </span>

              </p>
              <p className={Style.totalIconsWrapper}>
                <Tooltip
                  title="Total Table"
                  open={opentable}
                  onOpen={() => setOpenTable(true)}
                  onClose={() => setOpenTable(false)}
                  arrow
                >
                  <TableRestaurantIcon fontSize="large" onTouchStart={handleTouchStartTable} />
                </Tooltip>
                <span className={Style.ml1} >
                  {totaTableCount}
                </span>

              </p>
            </div>}
            {!reservationExport && (
              <div>
                <Tooltip title="Wide search" arrow>
                  <IconButton
                    sx={
                      filterType === 0
                        ? {
                          borderBottom: "4px solid black",
                          borderRadius: 0,
                        }
                        : {}
                    }
                    onClick={() => {
                      setFilterType(0);
                      dispatch(setPreviousPageSize(""));
                      setSearchKey("");
                    }}
                  >
                    <img src={Wide} alt="wide_icon" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Narrow search" arrow>
                  <IconButton
                    sx={
                      filterType === 1
                        ? {
                          borderBottom: "4px solid black",
                          borderRadius: 0,
                        }
                        : {}
                    }
                    onClick={() => {
                      setFilterType(1);
                      dispatch(setPreviousPageSize(""));
                      setSearchKey("");
                    }}
                  >
                    <img src={Narrow} alt="narrow_icon" />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          {batchSizes.length > 0 && (
            <div className={Style.rangeWrapper}>
              {batchSizes?.map((range,index) => (
                <OptionPill
                  // key={range}
                  key={`${range}-${index}`} // Combine value and index for uniqueness
                  active={pageSize === range}
                  text={range}
                  onClick={() => handlePagination(range)}
                />
              ))}
              {/* <p className={Style.showAllText}>Show All</p> */}
            </div>
          )}
        </div>
        {batchSizes.length > 0 && <div className={Style.emptyLine}></div>}
        {populateFilters()?.length > 0 && (
          <div className={Style.filtersWrapper}>
            <p className={Style.clearAllText} onClick={clearAllFilters}>
              Clear All
            </p>

            <div className={Style.appliedFilterWrapper}>
              {populateFilters()?.map((filter, index) => (
                <OptionPill
                  isFilter
                  key={index}
                  text={filter.toString()}
                  active={true}
                />
              ))}
            </div>
          </div>
        )}
        <div>
          <TextField
            id="searchName"
            name="searchName"
            fullWidth
            autoComplete="off"
            type="text"
            value={searchKey}
            placeholder="Search"
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            inputProps={{
              className: Style.searchInput,
            }}
          />
        </div>
        <div className={Style.selectionInfo}>
          {role === USER_TYPE.MANAGER && searchData?.length > 0 && (
            <FormLabel
              onClick={handleSelectClick}
              sx={{ textDecoration: "", "&:hover": { cursor: "pointer" } }}
            >
              {getSelectLabel()}
            </FormLabel>
          )}
        </div>
        <Grid
          className={Style.filteredBlock}
          container
          spacing={!isMobile && 2}
          alignItems="center"
          justifyContent="normal"
        >
          {searchData?.map((customer,index) => (
            <Grid key={index} item xs={12} sm={12} md={6} lg={4}>
              {/* <div
                style={{
                  width: isMobile ? "100%" : "", //390px
                }}
              > */}
                <CustomerList
                  usedin="filtered"
                  data={customer}
                  dashboardActions
                  restaurantsList={restaurantsList}
                  showCheckBox
                  filtered={exports || reservationExport}
                  checkInOut={checkin ? true : false}
                  reservation
                  reservationExport={reservationExport}
                  reservationFilter={
                    location?.pathname === "/filtered-reservations"
                      ? true
                      : false
                  }
                  getData={getFilteredClientList}
                />
              {/* </div> */}
            </Grid>
          ))}
          {!searchData.length && !loading && !searchLoading && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="noResultWrapper">
                <img src={NoDataFound} alt="no-data-found" />
                <Typography variant="h5">No data found!</Typography>
              </div>
            </Grid>
          )}
          {searchLoading && (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="noResultWrapper">
                <CircularProgress color="primary" size={56} />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      {(selectedContacts?.length > 0 || selectedReservationIds?.length > 0) &&
        (exports || reservationExport ? (
          <div className={Style.submitBtnWrapper}>
            <Button
              size="medium"
              variant="contained"
              className={Style.nextBtn}
              onClick={handleOpen}
              disabled={submitting}
            >
              {submitting ? (
                <CircularProgress color="primary" size={35} />
              ) : (
                "Export"
              )}
            </Button>
            <div className={Style.footer}></div>
          </div>
        ) : (
          <div className={Style.submitBtnWrapper}>
            <Button
              size="medium"
              variant="contained"
              className={Style.nextBtn}
              onClick={handleNext}
              disabled={submitting}
            >
              {submitting ? (
                <CircularProgress color="primary" size={35} />
              ) : (
                "Next"
              )}
            </Button>
            <div className={Style.footer}></div>
          </div>
        ))}

      <Dialog
        open={openConfirmationDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">Confirm</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to Export selected data ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button variant="contained" onClick={handleExport} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Filtered;
