import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import Styles from "./login-form.module.css";
import {
  Alert,
  Button,
  CircularProgress,
  FormLabel,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ENDPOINT, ERROR_ACTIONS } from "../../config/constants";
import { resetError, setLoading } from "../../redux/slices/userSlice";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SECRET_KEY, RECAPTCHA_SITE_KEY } from "../../config/config";
import { POST } from "../../services/axiosRequestHandler";
import ReCaptcha from "../reCAPTCHA";
import useRecaptchaV3 from "../../hooks/useRecaptchaV3";

function LoginWithoutCaptcha() {
  // const executeRecaptcha = useRecaptchaV3(RECAPTCHA_SITE_KEY);
  const { loading, hasError, errorMessage } = useSelector(
    (state) => state.user
  );
  const { role } = useSelector((state) => state.user);
  const { login } = useAuth();
  const dispatch = useDispatch();
  const recaptchaRef = useRef(null);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [loginData, setLoginData] = useState({
    userName: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    userName: "",
    password: "",
  });
  const [time, setTime] = useState(180);
  const [disableLogin, setDisableLogin] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    let timer = null;
    if (hasError && errorMessage === ERROR_ACTIONS.ACCESS_BLOCKED) {
      timer = setInterval(() => {
        if (time > 0) {
          setDisableLogin(true);
          setTime(time - 1);
        } else {
          clearInterval(timer);
          dispatch(resetError());
          setDisableLogin(false);
          setTime(180);
        }
      }, 1000);
    } else {
      clearInterval(timer);
      setDisableLogin(false);
      setTime(180);
    }
    return () => {
      clearInterval(timer);
    };
  }, [time, hasError, loginData]);

  // useEffect(() => {
  //   resetRecaptcha();
  // }, [role, hasError]);

  const handleRecaptchaChange = async (value) => {
    setToken(value);
  };

  const resetRecaptcha = () => {
    if (recaptchaRef?.current) {
      recaptchaRef.current?.reset();
      setToken(null);
      setVerified(false);
    }
  };

  // const verifyToken = async () => {
  //   let result = false;
  //   const token = await executeRecaptcha("login");
  //   setToken(token);
  //   if (!token) {
  //     toast.error("reCAPTCHA is not verified!");
  //     return;
  //   }
  //   try {
  //     const response = await POST(ENDPOINT.VERIFY_CAPTCHA, {
  //       response: token,
  //     });
  //     const { success, errorCodes } = response?.response?.data;

  //     if (success) {
  //       result = true;
  //       setVerified(true);
  //     } else {
  //       result = false;
  //       setVerified(false);
  //     }
  //   } catch (error) {
  //     setVerified(false);
  //   } finally {
  //     return result;
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear any previous errors for the field
    dispatch(resetError());
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // if (isVerified) handleLogin();
      // else toast.error("reCAPTCHA is not verified!");
      handleLogin();
    }
  };

  const handleLogin = async () => {
    dispatch(setLoading(true));
    const { userName, password } = loginData;
    if (!userName) {
      setErrors((err) => ({
        ...err,
        userName: "Username is required.",
      }));
      if (usernameRef?.current) usernameRef?.current?.focus();
      dispatch(setLoading(false));
      return;
    }

    if (!password) {
      setErrors((err) => ({
        ...err,
        password: "Password is required.",
      }));
      if (passwordRef?.current) passwordRef?.current?.focus();
      dispatch(setLoading(false));
      return;
    }

    // if (!(await verifyToken())) {
    //   toast.error("reCAPTCHA verification failed!");
    //   dispatch(setLoading(false));
    //   return;
    // }

    login(loginData, role);
  };

  return (
    <div className={Styles.formContainer}>
      <div className={Styles.formWrapper}>
        <div className={Styles.inputWrapper}>
          <FormLabel sx={{ marginBottom: "11px" }}>Username</FormLabel>
          <TextField
            variant="outlined"
            inputRef={usernameRef}
            error={errors.userName ? true : false}
            id="userName"
            name="userName"
            autoComplete="off"
            type="text"
            value={loginData.userName}
            onChange={handleChange}
            helperText={errors.userName}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className={Styles.inputWrapper}>
          <FormLabel sx={{ marginBottom: "11px" }}>Password</FormLabel>
          <TextField
            variant="outlined"
            inputRef={passwordRef}
            id="password"
            name="password"
            autoComplete="off"
            type="password"
            value={loginData.password}
            onChange={handleChange}
            error={errors.password ? true : false}
            helperText={errors.password}
            onKeyDown={handleKeyDown}
          />
        </div>
        {hasError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errorMessage === ERROR_ACTIONS.ACCESS_BLOCKED
              ? `Account temporarily locked. Please try again after ${Math.floor(
                  time / 60
                )}:${time % 60 < 10 ? `0${time % 60}` : time % 60} minutes.`
              : errorMessage}
          </Alert>
        )}
        {/* {!disableLogin && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={handleRecaptchaChange}
            onExpired={() => {
              setToken(null);
            }}
            onErrored={() => {
              setToken(null);
            }}
          />
          <ReCaptcha
            siteKey={RECAPTCHA_SITE_KEY}
            callback={handleRecaptchaChange}
            onExpired={() => {
              setToken(null);
              setVerified(false);
            }}
            onErrored={() => {
              setToken(null);
              setVerified(false);
            }}
            resetStates={{ role, hasError }}
          />
        )} */}
        <div className={Styles.buttonWrapper}>
          <Button
            className={Styles.loginBtn}
            disabled={loading || disableLogin}
            size="medium"
            variant="contained"
            onClick={handleLogin}
          >
            {loading ? (
              <CircularProgress
                variant="indeterminate"
                color="primary"
                size={30}
              />
            ) : (
              "Login"
            )}
          </Button>
          {/* <a href="#" className={Styles.aaa}>
            Login with SSO</a> */}
        </div>
        {/* <div className={Styles.recaptchaText}>
          This site is protected by reCAPTCHA and the Google <br />
          <a href="https://policies.google.com/privacy">
            Privacy Policy
          </a> and{" "}
          <a href="https://policies.google.com/terms">Terms of Service</a>{" "}
          apply.
        </div> */}
      </div>
    </div>
  );
}

export default LoginWithoutCaptcha;