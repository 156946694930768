import React, { useEffect, useState } from "react";
import CustomerList from "../dashboard/CustomerList";
import NoDataFound from "../../components/no-data-found/NoDataFound";
import { useDispatch } from "react-redux";
import { getList } from "../../services/userServices";
import { ENDPOINT, ERROR_ACTIONS } from "../../config/constants";
import {
  setAllClientList,
  setCompletedClientList,
  setPendingClientList,
} from "../../redux/slices/masterDataSlice";
import { useAuth } from "../../hooks/useAuth";
import { PAGE_SIZE } from "../../config/config";
import { Grid, CircularProgress, Box, Pagination } from "@mui/material";
import { POST_MULTIPART } from "../../services/axiosRequestHandler";

const CompletedList = ({ searchKey }) => {
  const dispatch = useDispatch();
  const { logout, role } = useAuth();
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    firstLoad && !searchKey && getCompletedClientList();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchKey]);

  useEffect(() => {
    if (!firstLoad) {
      const searchData = setTimeout(() => {
        searchKey ? searchList() : getCompletedClientList();
      }, 1000);

      return () => {
        clearTimeout(searchData);
        setSearchData([]);
        setLoading(true);
      };
    }
  }, [searchKey, currentPage]);

  const searchList = async () => {
    try {
      const payload = new FormData();
      payload.append("search", searchKey);
      payload.append("type", "completed");
      payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
      const resp = await POST_MULTIPART(ENDPOINT.CLIENT_SEARCH, payload);
      if (resp?.response?.data?.status === 200) {
        setSearchData(resp?.response?.data?.data);
        setTotalPageCount(
          resp?.response?.data?.totalRecordsCount > PAGE_SIZE
            ? Math.ceil(resp?.response?.data?.totalRecordsCount / PAGE_SIZE)
            : 0
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCompletedClientList = async () => {
    setLoading(true);
    const payload = new FormData();
    payload.append("previousCount", currentPage * PAGE_SIZE - PAGE_SIZE);
    const resp = await getList(
      "POST",
      ENDPOINT.GET_COMPLETED_COMPLETED_FOR_ADMIN,
      payload
    );
    if (!resp?.error) {
      dispatch(setCompletedClientList(resp?.data));
      setSearchData(resp?.data?.data);
      setTotalPageCount(
        resp?.data?.totalRecordsCount > PAGE_SIZE
          ? Math.ceil(resp?.data?.totalRecordsCount / PAGE_SIZE)
          : 0
      );
      setLoading(false);
      setFirstLoad(false);
    } else if (resp?.error === ERROR_ACTIONS.SESSION_EXPIRED) {
      setLoading(false);
      logout();
    }
  };

  if (loading) {
    return (
      <div className="loaderWrapper">
        <CircularProgress size={50} />
      </div>
    );
  }
  return (
    <div>
      <Grid container spacing={2} sx={{ mb: 12 }}>
        {searchData?.map((customer) => (
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <CustomerList
              usedin="admin"
              key={customer.id}
              data={customer}
              dashboardActions
              showCheckBox={false}
            />
          </Grid>
        ))}
      </Grid>
      {searchData?.length === 0 ? <NoDataFound /> : null}
      <Grid item xs={12} lg={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
            mb: "100px",
          }}
        >
          {searchData?.length > 0 && totalPageCount > 0 ? (
            <Pagination
              count={totalPageCount}
              variant="outlined"
              shape="rounded"
              color="primary"
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
            />
          ) : null}
        </Box>
      </Grid>
    </div>
  );
};

export default CompletedList;
