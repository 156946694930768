import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setServer } from "../../redux/slices/masterDataSlice";
import { POST_MULTIPART } from "../../services/axiosRequestHandler";
import { ENDPOINT, TOAST_CONFIG } from "../../config/constants";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";

const ChooseServerModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.user);
  const { server } = useSelector((state) => state.masterData);
  const [selectedValue, setSelectedValue] = useState(server);
  useEffect(() => {
    setSelectedValue(server);
  }, [server]);
  const handleChange = async (e) => {
    dispatch(setServer(e.target.value));
    setSelectedValue(e.target.value);
    try {
      const payload = new FormData();
      payload.append("adminId", data?.id);
      payload.append("sendingBy", e.target.value);
      const resp = await POST_MULTIPART(ENDPOINT.CHOOSE_SERVER, payload);
      if (resp?.response?.data?.status === 200) {
        toast.success(resp?.response?.data?.message, TOAST_CONFIG);
      } else {
        toast.error(resp.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    } finally {
      handleClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Please select your Messenger Server.
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedValue}
          onChange={handleChange}
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <FormControlLabel
            value="weblas"
            control={<Radio />}
            label="Server 1"
          />
          <FormControlLabel
            value="wassenger"
            // value="weblas2"
            control={<Radio />}
            label="Server 2"
          />
          <FormControlLabel
            value="botspace"
            control={<Radio />}
            label="Server 3"
          />
        </RadioGroup>
      </DialogContent>
    </Dialog>
  );
};

export default ChooseServerModal;
