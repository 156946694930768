import React from "react";
import { Grid } from "@mui/material";
import Logo from "../../assets/images/DatalektLogo.png";
import LoginForm from "../../components/login-form/LoginForm";
import AdminStaffToggle from "../../components/admin-staff-toggle/AdminStaffToggle";
import Styles from "../../components/login-form/login-form.module.css";
import LoginWithoutCaptcha from "../../components/login-form/LoginWithoutCaptcha";

const Login = () => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <div className={Styles.logoContainer}>
          <img className={Styles.logo} alt="logo" src={Logo} />
        </div>
        <AdminStaffToggle />
          {process.env.REACT_APP_ENV === "prod" ? <LoginForm /> : <LoginWithoutCaptcha />}
      </Grid>
    </Grid>
  );
};

export default Login;
