import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  InputLabel,
  Rating,
  Button,
  useMediaQuery,
  DialogActions,
  IconButton,
  FormLabel,
  Typography,
  Slider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Style from "./filter.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import OptionPill from "../option-pill/OptionPill";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  setAgeData,
  setCategoriesData,
  setMainFilter,
  setOpenCheckInFilter,
  setOpenMainFilter,
  setOpenReservationFilter,
  setPreviousPageSize,
  setRestaurantsList,
  setSelectedContacts,
  setSelectedIds,
} from "../../redux/slices/masterDataSlice";
import { toast } from "react-toastify";
import {
  ENDPOINT,
  TOAST_CONFIG,
  USER_TYPE,
  genderData,
} from "../../config/constants";
import { GET } from "../../services/axiosRequestHandler";
import { useAuth } from "../../hooks/useAuth";
import { useLocation, useNavigate } from "react-router-dom";
import TimeSelector from "../time-selector/TimeSelector";
import SpendingSelectorMobile from "./SpendingSelectorMobile";

const age = ["16-20", "21-30", "31-40", "41-50", "51-60"];

const categories = [
  "3",
  "AASMANA",
  "UKIYO",
  "RCTL",
  "ALTA VIDA",
  "LIVING ROOM",
  "SOCIALITES",
  "REGULAR",
  "CORPORATE",
  "SPA",
  "MD CEO",
  "EXPAT",
  "HIGH SPENDER",
  "INFLUENCER",
  "VIP",
  "IN HOUSE",
];

const timeSlotProps = {
  textField: {
    placeholder: "Time",
    variant: "standard",
    sx: {
      // width: "105px",
      width: "100%",
      borderBottom: "2px",
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid #D9D9D9",
      },
      marginRight: "30px",
    },
    inputProps: {
      fontSize: "16px",
      fontWeight: "400",
      marginBottom: "5px",
    },
    fullWidth: true,
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

const slotProps = {
  textField: {
    placeholder: "Date",
    variant: "standard",
    sx: {
      width: "100%",
      borderBottom: "2px",
      "& .MuiInput-underline:before": {
        borderBottom: "2px solid #D9D9D9",
      },
    },
    inputProps: {
      fontSize: "16px",
      fontWeight: "400",
      marginBottom: "5px",
    },
  },
  actionBar: {
    actions: ["clear", "accept"],
  },
};

const spendingData = [
  {
    label: "₹ 2K",
    amount: "2K",
  },
  {
    label: "₹ 4K",
    amount: "4K",
  },
  {
    label: "₹ 6K",
    amount: "6K",
  },
  {
    label: "₹ 10K+",
    amount: "10K+",
  },
];

function Filter({ open, type }) {
  const isMobile = useMediaQuery("(max-width: 600px)");

  const { logout, role } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    openMainFilter,
    age,
    categories,
    mainFilter,
    filterReset,
    restaurantsList,
  } = useSelector((state) => state.masterData);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(mainFilter);
  const [fromDateError, setFromDateError] = useState("");
  const [startTimeError, setStartTimeError] = useState("");

  const [toDateError, setToDateError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");
  const [fromTime, setFromTime] = useState(
    mainFilter?.fromTime || {
      hh: "",
      mm: "",
      meridiem: "",
    }
  );

  const [toTime, setToTime] = useState(
    mainFilter?.toTime || {
      hh: "",
      mm: "",
      meridiem: "",
    }
  );

  const [fromTimeError, setFromTimeError] = useState(null);

  const [toTimeError, setToTimeError] = useState(null);
  

  useEffect(() => {
    getAgeData();
    getCategoryData();
    if (type === 0) {
      getAllRestaurants();
    }
  }, []);

  useEffect(() => {
    setFormData(mainFilter);
  }, [mainFilter]);

  const getAllRestaurants = async () => {
    try {
      const resp = await GET(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_ALL_RESTAURANTS_FOR_ADMIN
          : ENDPOINT.GET_ALL_RESTAURANTS
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setRestaurantsList(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const getAgeData = async () => {
    try {
      const resp = await GET(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_AGE_FOR_ADMIN
          : ENDPOINT.GET_AGE
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setAgeData(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        toast.error("Your session has expired!", TOAST_CONFIG);
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const getCategoryData = async () => {
    try {
      const resp = await GET(
        role === USER_TYPE.MANAGER
          ? ENDPOINT.GET_CATEGORIES_FOR_ADMIN
          : ENDPOINT.GET_CATEGORY
      );
      if (resp?.response?.data?.status === 200) {
        dispatch(setCategoriesData(resp?.response?.data));
      } else if (
        resp?.response?.data?.status === 401 &&
        resp?.response?.data?.message === "User Token Expired"
      ) {
        logout();
      } else {
        toast.error(resp?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error?.message, TOAST_CONFIG);
    }
  };

  const handleRatingChange = (newValue) => {
    setFormData({
      ...formData,
      rating: newValue,
    });
  };

  const handleAgeOptionPillClick = (newAgeOption) => {
    const isAgeSelected = formData.age.includes(newAgeOption.id);
    if (isAgeSelected) {
      setFormData({
        ...formData,
        age: formData.age.filter((a) => a !== newAgeOption.id),
      });
    } else {
      setFormData({
        ...formData,
        age: [...formData.age, newAgeOption.id],
      });
    }
  };

  // const handleGenderOptionPillClick = (newGenderOption) => {
  //   setFormData({
  //     ...formData,
  //     gender: newGenderOption,
  //   });
  // };

  const handleGenderOptionPillClick = (newGenderOption) => {
    // const isGenderSelected = formData.gender === newGenderOption.id;
    // if (isGenderSelected) {
    //   setFormData({
    //     ...formData,
    //     gender: null,
    //   });
    // } else {
    //   setFormData({
    //     ...formData,
    //     gender: newGenderOption.id,
    //   });
    // }

    const isGenderSelected = formData?.gender?.includes(newGenderOption.label);
    if (isGenderSelected) {
      setFormData({
        ...formData,
        gender: formData?.gender?.filter((a) => a !== newGenderOption.label),
      });
    } else {
      setFormData({
        ...formData,
        gender: [...formData?.gender, newGenderOption.label],
      });
    }
  };

  const handleSpendingOptionPillClick = (amount) => {
    const isExist = formData?.spending?.includes(amount);
    if (isExist) {
      setFormData({
        ...formData,
        spending: formData?.spending?.filter((a) => a !== amount),
      });
    } else
      setFormData({
        ...formData,
        spending: [...formData.spending, amount],
      });
  };

  const handleChangeSpending = (e, newValue) => {
    setFormData((prev) => ({ ...prev, spending: newValue }));
  };
  const handleCategoryOptionPillClick = (newCategoryOption) => {
    const isCategorySelected = formData.category.includes(newCategoryOption.id);

    if (isCategorySelected) {
      setFormData({
        ...formData,
        category: formData.category.filter(
          (category) => category !== newCategoryOption.id
        ),
      });
    } else {
      setFormData({
        ...formData,
        category: [...formData.category, newCategoryOption.id],
      });
    }
  };

  const handleOptionPillClick = (propName) => {
    setFormData({
      ...formData,
      [propName]: !formData[propName],
    });
  };

  const handleFromDateChange = (value) => {
    setFromDateError("");
    setFormData({
      ...formData,
      fromDate: value,
    });
  };

  const handleStartTimeChange = (value) => {
    setStartTimeError("");
    setFormData({
      ...formData,
      startTime: value,
    });
  };

  const handleToDateChange = (value) => {
    setToDateError("");
    setFormData({
      ...formData,
      toDate: value,
    });
  };

  const handleEndTimeChange = (value) => {
    setEndTimeError("");
    setFormData({
      ...formData,
      endTime: value,
    });
  };

  const handleSubmit = () => {
    dispatch(setPreviousPageSize(""));    
    const newFilter = { ...formData };
    if (fromTime.hh && fromTime.mm && fromTime.meridiem) {
      newFilter.fromTime = fromTime;
      if (!(toTime.hh && toTime.mm && toTime.meridiem)) {
        setToTimeError("required!");
        return;
      }
    } else newFilter.fromTime = null;

    if (toTime.hh && toTime.mm && toTime.meridiem) {
      newFilter.toTime = toTime;
      if (!(fromTime.hh && fromTime.mm && fromTime.meridiem)) {
        setFromTimeError("required!");
        return;
      }
    } else newFilter.toTime = null;

    if (type === 1) {
      if (formData.fromDate && !formData.startTime) {
        setStartTimeError("required!");
        return;
      } else if (!formData.fromDate && formData.startTime) {
        setFromDateError("required!");
        return;
      }

      if (formData.toDate && !formData.endTime) {
        setEndTimeError("required!");
        return;
      } else if (!formData.toDate && formData.endTime) {
        setToDateError("required!");
        return;
      }

      if (formData.fromDate && !formData.toDate) {
        setToDateError("required!");
        return;
      } else if (!formData.fromDate && formData.toDate) {
        setFromDateError("required!");
        return;
      }
    } else if (type === 2) {
      if (formData?.fromDate && !formData?.toDate) {
        setToDateError("required!");
        return;
      } else if (!formData?.fromDate && formData?.toDate) {
        setFromDateError("required!");
        return;
      }
    }
    dispatch(setMainFilter(newFilter));
    dispatch(setSelectedContacts([]));
    dispatch(setSelectedIds([]));

    handleClose();
    location.pathname === "/export"
      ? navigate("/export")
      : type === 0
      ? navigate("/filtered-reservations")
      : type === 2
      ? navigate("/filtered-checkin")
      : navigate("/filtered");
  };

  const handleClose = () => {
    dispatch(setOpenMainFilter(false));
    dispatch(setOpenReservationFilter(false));
    dispatch(setOpenCheckInFilter(false));
  };

  const handleToTime = (e) => {
    setToTimeError(null);
    setToTime((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFromTime = (e) => {
    setFromTimeError(null);
    setFromTime((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const valuetext = (value) => {
    return `${value}K`;
  };
  return (
    <div>
      <Dialog
        open={open}
        fullScreen={isMobile}
        onClose={handleClose}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle className={Style.dialogTitle}>
          <div className={Style.dialogTitleWrapper}>
            {type === 0 && <p>Reservation Filter</p>}
            {type === 1 && <p>Database Filter</p>}
            {type === 2 && <p>Check In / Out Filter</p>}
            <IconButton onClick={handleClose}>
              <CloseIcon
                sx={{
                  backgroundColor: "white",
                  borderRadius: "99px",
                  fontSize: "xx-large",
                }}
              />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent sx={{ padding: "0", marginTop: "48px" }}>
          <div className={Style.dialogContentWrapper}>
            <Box
              sx={{
                mb: "30px",
              }}
            >
              <InputLabel
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  mb: "10px",
                }}
              >
                From :
              </InputLabel>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    format="DD/MM/YYYY"
                    value={formData.fromDate && dayjs(formData.fromDate)}
                    slotProps={slotProps}
                    onChange={handleFromDateChange}
                  />
                </LocalizationProvider>
                {fromDateError && (
                  <p style={{ color: "red" }}>{fromDateError}</p>
                )}
              </div>
            </Box>
            <Box
              sx={{
                mb: "30px",
              }}
            >
              {type === 0 && (
                <TimeSelector
                  values={fromTime}
                  onChange={handleFromTime}
                  error={
                    fromTimeError && {
                      hh: fromTimeError,
                      mm: fromTimeError,
                      meridiem: fromTimeError,
                    }
                  }
                />
              )}
              {type === 1 && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      slotProps={timeSlotProps}
                      value={formData.startTime && dayjs(formData.startTime)}
                      views={["hours", "minutes"]}
                      openTo="hours"
                      onChange={handleStartTimeChange}
                      ampm={true}
                    />
                  </LocalizationProvider>
                  {startTimeError && (
                    <p style={{ color: "red" }}>{startTimeError}</p>
                  )}
                </>
              )}
            </Box>

            <Box
              sx={{
                mb: "30px",
              }}
            >
              <InputLabel
                sx={{
                  fontSize: "16px",
                  fontWeight: "400",
                  mb: "10px",
                }}
              >
                To :
              </InputLabel>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    format="DD/MM/YYYY"
                    slotProps={slotProps}
                    value={formData.toDate && dayjs(formData.toDate)}
                    onChange={handleToDateChange}
                  />
                </LocalizationProvider>
                {toDateError && <p style={{ color: "red" }}>{toDateError}</p>}
              </div>
            </Box>

            <Box
              sx={{
                mb: "30px",
              }}
            >
              {type === 0 && (
                <TimeSelector
                  values={toTime}
                  onChange={handleToTime}
                  error={
                    toTimeError && {
                      hh: toTimeError,
                      mm: toTimeError,
                      meridiem: toTimeError,
                    }
                  }
                />
              )}
              {type === 1 && (
                <>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileTimePicker
                      slotProps={timeSlotProps}
                      value={formData.endTime && dayjs(formData.endTime)}
                      views={["hours", "minutes"]}
                      onChange={handleEndTimeChange}
                      ampm={true}
                    />
                  </LocalizationProvider>
                  {endTimeError && (
                    <p style={{ color: "red" }}>{endTimeError}</p>
                  )}
                </>
              )}
            </Box>
            {type > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: "30px",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    width: "85px",
                    mr: "20px",
                  }}
                >
                  Rating
                </InputLabel>
                <Rating
                  size="large"
                  color="primary"
                  sx={{
                    color: "primary",
                  }}
                  value={formData.rating}
                  onChange={(event, newValue) => handleRatingChange(newValue)}
                />
              </Box>
            )}
            {type > 0 && (
              <Box
                sx={{
                  mb: "30px",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mr: "20px",
                      mb: "10px",
                    }}
                  >
                    Age
                  </InputLabel>
                </Box>
                <Box className={Style.OptionPillWrapper}>
                  {age?.data?.data?.map((ageOption) => (
                    <OptionPill
                      key={ageOption.id}
                      active={formData.age.includes(ageOption.id)}
                      text={`${ageOption.ageFrom}-${ageOption.ageTo}`}
                      onClick={() => handleAgeOptionPillClick(ageOption)}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {type > 0 && (
              <Box
                sx={{
                  mb: "30px",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mr: "20px",
                      mb: "10px",
                    }}
                  >
                    Categories
                  </InputLabel>
                </Box>
                <Box className={Style.OptionPillWrapper}>
                  {categories?.data?.data?.map((category) => (
                    <OptionPill
                      key={category.id}
                      active={formData.category.includes(category.id)}
                      text={category.name}
                      onClick={() => handleCategoryOptionPillClick(category)}
                    />
                  ))}
                </Box>
              </Box>
            )}
            {type === 0 && (
              <Box
                sx={{
                  mb: "30px",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mr: "20px",
                      mb: "10px",
                    }}
                  >
                    Restaurants
                  </InputLabel>
                </Box>
                <Box className={Style.OptionPillWrapper}>
                  {restaurantsList?.data?.data?.original?.map((category) => (
                    <OptionPill
                      key={category.id}
                      active={formData.category.includes(category.id)}
                      text={category.name}
                      onClick={() => handleCategoryOptionPillClick(category)}
                    />
                  ))}
                </Box>
              </Box>
            )}

            <Box
              sx={{
                mb: "30px",
              }}
            >
              <Box
                sx={{
                  borderBottom: "2px solid #D9D9D9",
                  marginBottom: "15px",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    mr: "20px",
                    mb: "10px",
                  }}
                >
                  Do Not Disturb
                </InputLabel>
              </Box>
              <Box
                sx={{
                  width: "351px",
                  flexGrow: "1",
                }}
              >
                <OptionPill
                  active={formData.dnd}
                  text="DND"
                  onClick={() => handleOptionPillClick("dnd")}
                />
              </Box>
            </Box>

            <Box
              sx={{
                mb: "30px",
              }}
            >
              <Box
                sx={{
                  borderBottom: "2px solid #D9D9D9",
                  marginBottom: "15px",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    mr: "20px",
                    mb: "10px",
                  }}
                >
                  Cancelled Reservations
                </InputLabel>
              </Box>
              <Box
                sx={{
                  width: "351px",
                  flexGrow: "1",
                }}
              >
                <OptionPill
                  active={formData.isCancelled}
                  text="Cancel"
                  onClick={() => handleOptionPillClick("isCancelled")}
                />
              </Box>
            </Box>

            {type === 0 && (
              <Box
                sx={{
                  mb: "37px",
                }}
              >
                <Box
                  sx={{
                    borderBottom: "2px solid #D9D9D9",
                    marginBottom: "15px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <InputLabel
                    sx={{
                      fontSize: "16px",
                      fontWeight: "400",
                      mr: "20px",
                      mb: "10px",
                    }}
                  >
                    Spending
                  </InputLabel>

                  {formData.spending.length > 0 && (
                    <InputLabel
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        mb: "10px",
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFormData((prev) => ({ ...prev, spending: [] }));
                      }}
                    >
                      Reset
                    </InputLabel>
                  )}
                </Box>
                <Box
                  sx={{
                    width: !isMobile ? "359px" : "100%",
                    flexGrow: "1",
                    my: 5,
                  }}
                >
                  {/* {spendingData.map((item) => (
                    <OptionPill
                      key={item.label}
                      active={formData?.spending?.includes(item.amount)}
                      text={item.label}
                      onClick={() => handleSpendingOptionPillClick(item.amount)}
                    />
                  ))} */}

                  {/* {isMobile ? (
                    <SpendingSelectorMobile
                      spending={
                        formData.spending.length ? formData.spending : [0, 0]
                      }
                      handleChange={handleChangeSpending}
                    />
                  ) : ( */}
                    <Slider
                      getAriaLabel={() => "Spending range"}
                      value={
                        formData.spending.length ? formData.spending : [0, 0]
                      }
                      onChange={handleChangeSpending}
                      valueLabelDisplay="on"
                      getAriaValueText={valuetext}
                      valueLabelFormat={valuetext}
                      min={1}
                      // max={100}
                    />
                  {/* )} */}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                mb: "37px",
              }}
            >
              <Box
                sx={{
                  borderBottom: "2px solid #D9D9D9",
                  marginBottom: "15px",
                }}
              >
                <InputLabel
                  sx={{
                    fontSize: "16px",
                    fontWeight: "400",
                    mr: "20px",
                    mb: "10px",
                  }}
                >
                  Gender
                </InputLabel>
              </Box>
              <Box
                sx={{
                  width: !isMobile ? "359px" : "75%",

                  flexGrow: "1",
                }}
              >
                {genderData.map((item) => (
                  <OptionPill
                    key={item.id}
                    active={formData?.gender?.includes(item.label)}
                    text={item.label}
                    onClick={() => handleGenderOptionPillClick(item)}
                  />
                ))}
              </Box>
            </Box>
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "0" }}>
          <div className={Style.btnWrapper}>
            <Button size="medium" variant="contained" onClick={handleSubmit}>
              Apply
            </Button>
            <div className={Style.footer}></div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Filter;
