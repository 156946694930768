import {
  Box,
  Modal,
  Typography,
  Dialog,
  Divider,
  TextField,
  Button,
  IconButton,
  ToggleButton,
  CircularProgress,
  Container,
} from "@mui/material";
import styles from "./styles/changeTablePopupStyle.module.css";
import LiveTableStyles from "../../pages/liveTable/styles/LiveTable.module.css";
import { CELL_TYPE, ENDPOINT, TOAST_CONFIG } from "../../config/constants";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { POST_MULTIPART } from "../../services/axiosRequestHandler";
import { useSelector } from "react-redux";
import TBL1Active from "../../assets/icons/1-table-active.svg";
import TBL1Inactive from "../../assets/icons/1-table-inactive.svg";
import TBL1Arrived from "../../assets/icons/1-table-arrived.svg";
import TBL2Active from "../../assets/icons/2-table-active.svg";
import TBL2Inactive from "../../assets/icons/2-table-inactive.svg";
import TBL2Arrived from "../../assets/icons/2-table-arrived.svg";
import TBL4Active from "../../assets/icons/4-table-active.svg";
import TBL4Inactive from "../../assets/icons/4-table-inactive.svg";
import TBL4Arrived from "../../assets/icons/4-table-arrived.svg";
import TBL6Active from "../../assets/icons/6-table-active.svg";
import TBL6Inactive from "../../assets/icons/6-table-inactive.svg";
import TBL6Arrived from "../../assets/icons/6-table-arrived.svg";
import PDR6Active from "../../assets/icons/6-pdr-active.svg";
import PDR6Inactive from "../../assets/icons/6-pdr-inactive.svg";
import PDR6Arrived from "../../assets/icons/6-pdr-arrived.svg";
import PDR8Active from "../../assets/icons/8-pdr-active.svg";
import PDR8Inactive from "../../assets/icons/8-pdr-inactive.svg";
import PDR8Arrived from "../../assets/icons/8-pdr-arrived.svg";
import PDR10Active from "../../assets/icons/10-pdr-active.svg";
import PDR10Inactive from "../../assets/icons/10-pdr-inactive.svg";
import PDR10Arrived from "../../assets/icons/10-pdr-arrived.svg";
import PDR12Active from "../../assets/icons/12-pdr-active.svg";
import PDR12Inactive from "../../assets/icons/12-pdr-inactive.svg";
import PDR12Arrived from "../../assets/icons/12-pdr-arrived.svg";
import RL from "@mui/icons-material/West";
import LR from "@mui/icons-material/East";
import TB from "@mui/icons-material/South";
import BT from "@mui/icons-material/North";
import CancelIcon from "@mui/icons-material/Cancel";
import OptionPill from "../option-pill/OptionPill";
import {
  TransformWrapper,
  TransformComponent,
  useControls,
} from "react-zoom-pan-pinch";
import Controls from "../controls/Controls";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import TimeSelector from "../time-selector/TimeSelector";
import { dataURLtoFile, formatAMPM, formatISODateString } from "../../utils";
// import Ticket from "../ticket/Ticket";


const style = {
  position: "absolute",
  top: "0px",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  px: 3,
  py: 4,
  borderRadius: "16px",
  outline: "none",
  overflow: "auto",
};

const ChangeTablePopup = ({
  open,
  handleClose,
  layout,
  bookingDetails,
  restId,
  restaurantsList,
  handleRestaurantOptionPillClick,
  zoomState,
  slotProps,
  dateTimeSelector,
  date,
  time,
  usedin
}) => {
  const domE2 = useRef(null);
  const { data } = useSelector((state) => state.user);
  const { server } = useSelector((state) => state.masterData);
  const [localRestId, setLocalRestId] = useState(restId);
  const [bookedTbl, setBookedTbl] = useState([]);
  const [formData, setFormData] = useState(bookingDetails);
  const [submitting, setSubmistting] = useState(false);
  const [numberOfTablesToSelect, setNumberOfTablesToSelect] = useState(0);
  const [initialTables, setInitialTables] = useState([]);
  const [bookingTicket, setBookingTicket] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [restaurant, setRestaurant] = useState(null);
  const includeRemark = localStorage.getItem('includeRemark')


  useEffect(() => {
      setLocalRestId(restId);
      const rest = restaurantsList?.data?.data?.original?.find(
        (r) => r.id === restId
      );
      rest && setRestaurant(rest);
  }, [restId, restaurantsList?.data?.data?.original]);

  const checkValueInString = (value, number) => {
    if(!value) return false;
    const valArray = value?.split(",").map((item) => item.trim());
    const result = valArray?.some((item) => (item === number));
    return result;
  };

  useEffect(() => {
    if (restaurantsList?.data?.data?.original && bookingDetails && restId) {
      try {
        const restIdx = restaurantsList?.data?.data?.original?.findIndex(
          (r) => r.id === restId
        );      
        const __restoLayout = JSON.parse(restaurantsList?.data?.data?.original[restIdx]?.restroLayout);
        // const relatedTables = __restoLayout?.data?.filter(
        //   (t) => t.id === ('reservationId' in bookingDetails ? bookingDetails?.reservationId : bookingDetails?.id)
        // );
        if( bookingDetails?.number) {
          const data = __restoLayout?.data?.map(
            (t) =>{
              if (t.type === "PDR" ) {
                if ((checkValueInString(bookingDetails?.number, t.number) && bookingDetails?.x === t.x && bookingDetails?.y === t.y) || t.booked) {
                  return {...t, booked: true}
                } else {
                  return {...t, booked: false}
                }
              } else  {
                if ((checkValueInString(bookingDetails?.number, t.number) && bookingDetails?.x === t.x && bookingDetails?.y === t.y) || t.booked) {
                  return {...t, booked: true}
                } else {
                  return {...t, booked: false}
                }
              }
              
            }
          );
          const alreadyBooked= data?.filter(
            (t) => t.booked
          )
          setInitialTables({...__restoLayout, data});
          setBookedTbl(alreadyBooked)
        } else if(bookingDetails?.tableNumber){
          const data = __restoLayout?.data?.map(
            (t) =>{
              if (t.type === "PDR") {
                if (checkValueInString(bookingDetails?.pdrNumberDetails, t.number)|| t.booked) {
                  return {...t, booked: true}
                } else {
                  return {...t, booked: false}
                }
              } else  {
                if (checkValueInString(bookingDetails?.tableNumber, t.number) || t.booked) {
                  return {...t, booked: true}
                } else {
                  return {...t, booked: false}
                }
              }
              
            }
          );
          const alreadyBooked= data?.filter(
            (t) => t.booked
          )
          setInitialTables({...__restoLayout, data});
          setBookedTbl(alreadyBooked)
        } 
        else {
          const data = __restoLayout?.data?.map(
            (t) =>{
              if (t.type === "PDR") {
                if (checkValueInString(bookingDetails?.pdrNumberDetails, t.number)|| t.booked) {
                  return {...t, booked: true}
                } else {
                  return {...t, booked: false}
                }
              } else  {
                if (checkValueInString(bookingDetails?.tableNumberDetails, t.number)|| t.booked) {
                  return {...t, booked: true}
                } else {
                  return {...t, booked: false}
                }
              }
              
            }
          );
          const alreadyBooked= data?.filter(
            (t) => t.booked
          )
          setInitialTables({...__restoLayout, data});
          setBookedTbl(alreadyBooked)
        }
        // setNumberOfTablesToSelect(relatedTables?.length || 0);
        setFormData(bookingDetails);
      } catch (error) {
        console.log(error);
        
      }
    }
    return () => {
      setBookedTbl([]);
      setNumberOfTablesToSelect(0);
    };
  }, [bookingDetails, restId, restaurantsList?.data?.data?.original]);

  const onTableClick = (tbl) => {
    if (tbl?.booked && tbl.userId!==0 && bookingDetails?.userId !== tbl.userId) {
      return;
    }    
    const index = bookedTbl?.findIndex((t) => t?.number === tbl?.number && t.x === tbl.x && t.y === tbl.y);
    if (index >= 0) {
      const updatedInitialTables = {...initialTables, data: initialTables?.data?.map((t) => {
        if(t?.number === tbl?.number) {
          return {
            ...t,
            userId: t?.userId? t?.userId : bookingDetails?.userId,
            booked: false
          }
        }
        return t
      })};
      let copy = [...bookedTbl];
      copy.splice(index, 1);
      setBookedTbl(copy);
      setInitialTables(updatedInitialTables);
      return;
    } else {
      const updatedInitialTables = {...initialTables, data: initialTables?.data?.map((t) => {
        if(t?.number === tbl?.number && tbl.x === t.x && tbl.y === t.y) {
          return {
            ...t,
            userId: t?.userId? t?.userId : bookingDetails?.userId,
            booked: true
          }
        }
        return t
      })};
      setBookedTbl((prev) => [...prev, tbl]);
      setInitialTables(updatedInitialTables);
    }
  };

  const handleSubmit = async () => {
    // if (!bookedTbl?.length) {
    //   return;
    // 
    let newTables =  [...bookedTbl]?.filter((t) => t?.userId===0 || (t?.userId === formData?.userId));
    // if (initialTables?.length !== bookedTbl?.length) {
    //   const remainingTables = initialTables.slice(bookedTbl?.length);
    //   newTables = [...newTables, ...remainingTables];
    // }

    try {
      setSubmistting(true);
      const payload = new FormData();
      payload.append("reservationId", ('reservationId' in formData ? formData.reservationId : formData.id));
      payload.append("showRemark", includeRemark === 'true' ? 1 : 0);
      payload.append(
        "booking_remark",
        formData.booking_remark ? formData.booking_remark : ""
      );
      payload.append("spending", formData?.spending || "");
      // payload.append("spending", ('reservationId' in formData ? "" : formData?.spending));
      payload.append("firstName", formData?.name);
      payload.append("lastName", formData?.lastName);
      payload.append("contactNo", formData?.contactNo);
      payload.append("userId", formData?.userId);
      payload.append("staffId", formData?.staff_id);
      payload.append(
        "booking_time",
        `${time.hh}:${time.mm}:00 ${time.meridiem}`
      );
      payload.append(
        "booking_date",
        formatISODateString(new Date(date).toISOString())
      );
      payload.append("isChanged", 1);
      payload.append("restId", localRestId);
      payload.append(
        "people_count",
        formData.people_count
          ? formData.people_count
          : formData?.peopleCount
            ? formData?.peopleCount
            : ""
      );
      bookingTicket &&
        bookingTicket != "" &&
        payload.append(
          "bookinCard",
          bookingTicket
            ? dataURLtoFile(
              bookingTicket,
              `${formData.firstName}${formData?.lastName}-booking.png`
            )
            : ""
        );
      payload.append("sendingBy", server);
      newTables.forEach((tbl) => {
        // payload.append("tableDetails[]", tbl ? JSON.stringify(tbl) : "");
        // payload.append("tableNumber[]", tbl ? tbl.number : "")
        if (bookedTbl?.length > 0) {
          payload.append("tableDetails[]", tbl ? JSON.stringify(tbl) : "");
          payload.append("tableNumber[]", tbl ? tbl.number : "")
        }
        else {
          payload.append("tableDetails[]", tbl ? JSON.stringify(tbl) : "");
          payload.append("tableNumber[]", tbl ? tbl.number : '')
        }

      });

      const response = await POST_MULTIPART(
        ENDPOINT.UPDATE_RESERVATION_ADMIN,
        payload,
        false
      );
      if (response?.response?.data?.status === 200) {
        toast.success(response?.response?.data?.message, TOAST_CONFIG);
        handleClose();
      } else {
        toast.error(response?.response?.data?.message, TOAST_CONFIG);
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    } finally {
      setSubmistting(false);
    }
  };

  const checkTableSelected = (x, y) => {
    let result = false;
    let index = bookedTbl.findIndex((tbl) => tbl.x === x && tbl.y === y);
    // && bookingDetails?.restId === restId
    if (index >= 0) {
      result = true;
    }
    return result;
  };

  const getTableShape = (table, isActive) => {
    const { isArrived } = table;
    switch (parseInt(table?.capacity)) {
      case 1:
        return (
          <img
            className={styles.tblImage}
            alt="Table for 1"
            src={
              isActive && isArrived
                ? TBL1Arrived
                : isActive
                  ? TBL1Active
                  : TBL1Inactive
            }
          />
        );
      case 2:
        return (
          <img
            className={styles.tblImage}
            alt="Table for 2"
            src={
              isActive && isArrived
                ? TBL2Arrived
                : isActive
                  ? TBL2Active
                  : TBL2Inactive
            }
          />
        );
      case 4:
        return (
          <img
            className={styles.tblImage}
            alt="Table for 4"
            src={
              isActive && isArrived
                ? TBL4Arrived
                : isActive
                  ? TBL4Active
                  : TBL4Inactive
            }
          />
        );
      case 6:
        return (
          <img
            className={styles.tblImage}
            alt="Table for 6"
            src={
              isActive && isArrived
                ? TBL6Arrived
                : isActive
                  ? TBL6Active
                  : TBL6Inactive
            }
          />
        );
      default:
        return (
          <img
            className={styles.pdrImage}
            alt="Table"
            src={
              isActive && isArrived
                ? TBL4Arrived
                : isActive
                  ? TBL4Active
                  : TBL4Inactive
            }
          />
        );
    }
  };

  const getPDRShape = (pdr, isActive) => {
    const { isArrived } = pdr;
    switch (parseInt(pdr?.capacity)) {
      case 6:
        return (
          <img
            className={styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR6Arrived
                : isActive
                  ? PDR6Active
                  : PDR6Inactive
            }
            alt="Table for 6"
          />
        );
      case 8:
        return (
          <img
            className={styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR8Arrived
                : isActive
                  ? PDR8Active
                  : PDR8Inactive
            }
            alt="Table for 8"
          />
        );
      case 10:
        return (
          <img
            className={styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR10Arrived
                : isActive
                  ? PDR10Active
                  : PDR10Inactive
            }
            alt="Table for 10"
          />
        );
      case 12:
        return (
          <img
            className={styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR12Arrived
                : isActive
                  ? PDR12Active
                  : PDR12Inactive
            }
            alt="Table for 12"
          />
        );
      default:
        return (
          <img
            className={styles.pdrImage}
            src={
              isActive && isArrived
                ? PDR6Arrived
                : isActive
                  ? PDR6Active
                  : PDR6Inactive
            }
            alt="Table"
          />
        );
    }
  };

  const getEntranceDirection = (cell) => {
    switch (cell?.direction) {
      case "top-down":
        return <TB />;
      case "down-top":
        return <BT />;
      case "left-right":
        return <LR />;
      case "right-left":
        return <RL />;
      default:
        return null;
    }
  };

  const getCell = (row, col) => {
    const cellData = initialTables?.data?.filter(
      (cell) => cell.y === col && cell.x === row
    );

    if (cellData.length) {
      const cell = cellData[0];
      if (cell.type === CELL_TYPE.TABLE)
        return (
          <div className={styles.tableCell} onClick={() => onTableClick(cell)}>
            {getTableShape(
              cell,
              checkTableSelected(cell?.x, cell?.y) || cell?.booked
            )}
            <span className={styles.tblNumber}>{cell.number}</span>
          </div>
        );
      if (cell.type === CELL_TYPE.PDR)
        return (
          <div className={styles.tableCell} onClick={() => onTableClick(cell)}>
            {getPDRShape(
              cell,
              checkTableSelected(cell?.x, cell?.y) || cell?.booked
            )}
            <span className={styles.tblNumber}>{cell.number}</span>
          </div>
        );
      if (cell.type === CELL_TYPE.BAR)
        return (
          <div className={styles.cellInside}>
            <div id="bar" className={styles.barCell}></div>
          </div>
        );
      if (cell.type === CELL_TYPE.ENTRANCE)
        return (
          <div id="rntrance" className={styles.redCarpet}>
            {getEntranceDirection(cell)}
          </div>
        );
      return cell.type;
    } else return <div className={styles.emptyCell}></div>;
  };

  function renderGrid(__initialTables) {
    
    const grid = [];

    for (let i = 0; i < __initialTables?.row; i++) {
      const row = [];
      for (let j = 0; j < __initialTables?.col; j++) {
        // You can customize the content of each grid cell here
        row.push(
          <div className={styles.gridCell} key={`${i}-${j}`}>
            {/* {`${j}-${i}`} */}
            {getCell(j, i)}
          </div>
        );
      }
      grid.push(
        <div className={styles.gridRow} key={i}>
          {row}
        </div>
      );
    }

    return grid;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullScreen>
      <Container sx={{ py: 4 }}>
        <div className={styles.titleWrapper}>
          <p>Change Table</p>
          <IconButton
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onClick={handleClose}
          >
            <CancelIcon fontSize="large" sx={{ p: 1 }} />
          </IconButton>
        </div>
        <Divider sx={{ my: 3, width: "100%" }} />
        <Box>
          <p
            style={{
              fontWeight: "600",
              marginBottom: "15px",
              fontSize: "16px",
            }}
          >
            Restaurants:
          </p>
          <div>
            {restaurantsList?.data?.data?.original?.map((restaurant) => (
              <OptionPill
                key={restaurant.id}
                active={localRestId === restaurant.id}
                text={restaurant.name}
                onClick={() => {
                  setLocalRestId(restaurant.id);
                  setRestaurant(restaurant);
                  handleRestaurantOptionPillClick(restaurant, true);
                }}
              />
            ))}
          </div>
        </Box>
        <Divider sx={{ my: 3, width: "100%" }} />
        <div className={LiveTableStyles.dateTimeWrapper}>
          {dateTimeSelector}
        </div>
        <Divider sx={{ my: 3, width: "100%" }} />
        <Box
          sx={{
            position: "relative",
          }}
        >
          <TransformWrapper
            initialScale={zoomState?.scale || 0.8}
            minScale={0.1}
            doubleClick={{
              disabled: true,
            }}
            initialPositionX={zoomState?.positionX}
            initialPositionY={zoomState?.positionY}
            centerZoomedOut
          >
            <Controls />
            <TransformComponent
              wrapperStyle={{
                width: "100%",
                height: "60%",
                border: "2px solid #e3e3e3",
                borderRadius: "5px",
                marginBottom: "100px",
              }}
            >
              <div className={styles.layoutWrapper}>{initialTables && renderGrid(initialTables)}</div>
            </TransformComponent>
          </TransformWrapper>
        </Box>

        {/* <Box
          
        >
          <Ticket
            edit
            restaurant={restaurant}
            date={date}
            time={time}
            customerName={`${formData?.name} ${formData?.lastName}`}
            peopleCount={
              formData?.peopleCount
                ? formData?.peopleCount
                : formData?.people_count
            }
            handlBookingTicket={(img) => {
              setRefresh((prev) => prev++);
              handleBookingTicket(img);
            }}
            remark={bookingDetails?.booking_remark || null}
            themeDetails={themeDetails}
          />
        </Box> */}

        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <Divider sx={{ mb: 3 }} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 3,
            }}
          >
            <Button
              disabled={submitting}
              variant="contained"
              onClick={handleSubmit}
            >
              {submitting ? (
                <CircularProgress color="primary" size={30} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </Box>


      </Container>
    </Dialog>
  );
};

export default ChangeTablePopup;
